import styled from "styled-components";

const TitleDiv = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

export default TitleDiv;