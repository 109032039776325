import React from 'react';
import {Link} from "gatsby";

import H2 from "../styled-components/typography/H2";
import Divider from "../styled-components/divider/Divider";
import P from "../styled-components/typography/P";
import {
    Avatar,
    EventBox,
    SpeakerDiv,
    SpeakerWrapper,
    Time
} from "../styled-components/cards/EventCard/EventCardComponents";

const EventCard = ({
                       eventSlug,
                       eventTitle,
                       speakerSlug,
                       speakerName,
                       speakerAvatar,
                       time,
                       hero,
                       additional_speakers
                   }) => {
        return (
            <EventBox>
                <Time image={hero}>{time}</Time>
                <Link to={eventSlug ? ("/events/" + eventSlug) : "/"}>
                    <H2 small noMargin underline>{eventTitle}</H2>
                </Link>
                <Divider full/>
                <SpeakerWrapper>
                    <Link to={"/speakers/" + speakerSlug}>
                        <SpeakerDiv>
                            <Avatar src={speakerAvatar} alt={speakerName}/>
                            <P events>{speakerName}</P>
                        </SpeakerDiv>
                    </Link>
                    {additional_speakers && additional_speakers.length > 0 && additional_speakers.map((item, index) =>
                        <Link to={"/speakers/" + item.slug} key={index}>
                            <SpeakerDiv>
                                <Avatar src={item.avatar.url} alt={item.name}/>
                                <P events>{item.name}</P>
                            </SpeakerDiv>
                        </Link>
                    )}
                </SpeakerWrapper>
            </EventBox>
        );
    }
;

export default EventCard;