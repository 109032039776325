import styled from "styled-components"
import {Link} from "gatsby"

const LinkButton = styled(Link)`
  font-size: 16px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  border-radius: 40px;
  border: none;
  padding: 10px 30px 10px 30px;
  margin: 20px 0 20px 0;
  line-height: 100%;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  transition: background-color 0.2s;
  white-space: nowrap;
  color: white;
  background-color: ${props => props.disabled ? "#e0dee9" : "#ed655a"};
  pointer-events: ${props => props.disabled ? "none" : "unset"};

  &:hover {
    background-color: ${props => props.disabled ? "" : "#F0837B"};
  }

  @media screen and (max-width: 800px) {
    font-size: 13px;
    padding: 7px 21px 7px 21px;
    margin: 14px 0 14px 0;
  }

  ${props => {
    if (props.eventbutton) {
      return `
      border-radius: 0px;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      font-weight: 500;
  `
    }
  }}
`

export default LinkButton