import styled from "styled-components";
import Col from "./Col";

const InlineGrid = styled(Col)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px 20px;
  align-content: ${props => props.alignStart ? "start" : "center"};
  justify-items: center;
  width: 100%;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

export default InlineGrid