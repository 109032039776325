import styled from "styled-components";

const Border = styled.div`
  border: 1px solid #e0dee9;
  height: 50px;
  min-width: 50px;
  width: 100%;
  align-items: ${props => props.center2 ? "center" : ""};
  justify-items: ${props => props.center ? "center" : ""};
  display: grid;
`

export default Border;