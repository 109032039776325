import React from 'react';
import {graphql} from "gatsby";
import moment from "moment";

import Layout from "../components/layouts/Layout";
import EventCard from "../components/cards/EventCard";
import Seo from "../components/main/SEO";

import SectionLayoutGrid from "../components/styled-components/wrappers/SectionLayoutGrid"
import H2 from "../components/styled-components/typography/H2"
import Divider from "../components/styled-components/divider/Divider"
import Col from "../components/styled-components/wrappers/Col"
import P from "../components/styled-components/typography/P"
import LinkButton from "../components/styled-components/button/LinkButton"
import TitleDiv from "../components/styled-components/event-template/TitleDiv";
import AvatarDiv from "../components/styled-components/event-template/AvatarDiv";
import AvatarInlineDiv from "../components/styled-components/event-template/AvatarInlineDiv";
import EventInfoDiv from "../components/styled-components/event-template/EventInfoDiv";
import Border from "../components/styled-components/event-template/Border";
import TimeImg from "../components/styled-components/event-template/TimeImg";
import EventButtonDiv from "../components/styled-components/event-template/EventButtonDiv";
import EventMeetupLink from "../components/styled-components/event-template/EventMeetupLink";
import {Avatar} from "../components/styled-components/cards/EventCard/EventCardComponents";
import InlineGrid from "../components/styled-components/wrappers/InlineGrid";

const EventTemplate = ({data}) => {

    const {date, title, description, speaker, slug, additional_speakers} = data.event;
    const {allStrapiBlackowlEvents: {nodes}} = data;

    const isDisabled = moment(date).format() > moment().format();

    return (
        <Layout title="Az előadásról">
            <Seo title={title} description={"Az " + title + "előadás oldala."} keywords={"balasys, blackowl, előadás"}
                 url={"/events/" + slug}/>
            <SectionLayoutGrid smallGap>
                <Col full>
                    <TitleDiv>
                        <H2 large>{title}</H2>
                    </TitleDiv>
                </Col>
                <Col full>
                    <Divider/>
                </Col>
                <InlineGrid full>
                    <Col col4>
                        <AvatarDiv to={"/speakers/" + speaker.slug}>
                            <Avatar large center src={speaker.avatar.url} alt={speaker.name}/>
                            <AvatarInlineDiv>
                                <P eventAvatar>{speaker.name}</P>
                                <P eventPosition>{speaker.position}</P>
                            </AvatarInlineDiv>
                        </AvatarDiv>
                    </Col>
                    {additional_speakers && additional_speakers.length > 0 && additional_speakers.map((item, index) =>
                        <Col col4>
                            <AvatarDiv to={"/speakers/" + item.slug} key={index}>
                                <Avatar large center src={item.avatar.url} alt={item.name}/>
                                <AvatarInlineDiv>
                                    <P eventAvatar>{item.name}</P>
                                    <P eventPosition>{item.position}</P>
                                </AvatarInlineDiv>
                            </AvatarDiv>
                        </Col>
                    )}
                </InlineGrid>
                <Col col2 marginTop={"large"}>
                    <P eventAvatar>Rövid leírás</P>
                </Col>
                <Col col10 grid center marginTop={"large"}>
                    <Divider eventSmall center/>
                </Col>
                <Col col9 marginBottom="small">
                    <P description>{description}</P>
                </Col>
                <Col col3 marginBottom="small">
                    <EventInfoDiv>
                        <Border center center2>
                            <TimeImg src="/assets/Icons_calendar-icon.svg" alt="calendar"/>
                        </Border>
                        <Border center2>
                            <P time>{moment(date).locale("hu").format("LL")}</P>
                        </Border>
                        <Border center center2>
                            <TimeImg src="/assets/Icons_time-icon.svg" alt="time"/>
                        </Border>
                        <Border center2>
                            <P time>{moment(date).locale("hu").format("LT")}</P>
                        </Border>
                    </EventInfoDiv>
                    <EventButtonDiv>
                        <EventMeetupLink to={"/schedule"}>
                            A meetup programja
                        </EventMeetupLink>
                    </EventButtonDiv>
                    <EventButtonDiv>
                        <LinkButton eventbutton="true" to={"/#register"}
                                    disabled={!isDisabled}>Regisztráció</LinkButton>
                    </EventButtonDiv>
                </Col>
                <Col full center top>
                    <H2 large>A Meetup további előadásai</H2>
                </Col>
                <Col full center grid marginBottom="small">
                    <Divider center/>
                </Col>
                {nodes.map((item) => (
                    <Col col4 key={item.slug} marginBottom="small">
                        <EventCard
                            hero={item.Hero_Image.url}
                            time={moment(item.date).locale("hu").format("LLL")}
                            eventTitle={item.title}
                            eventSlug={item.slug}
                            speakerName={item.speaker.name}
                            speakerSlug={item.speaker.slug}
                            speakerAvatar={item.speaker.avatar.url}
                            additional_speakers={item.additional_speakers}
                        />
                    </Col>
                ))}
                <Col full center top marginBottom="small">
                    <LinkButton to={"/#register"} disabled={!isDisabled}>Regisztráció</LinkButton>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    );
};

export const query = graphql`
   query GetSingleEvent($slug: String, $meetup: String) {
         event: strapiBlackowlEvents(slug: { eq: $slug }) {
            date
            title
            description
            slug
            strapiId
            Hero_Image{
                url
            }
            speaker {
                  avatar {
                    url
                  }
                  name
                  position
                  slug
            }
            additional_speakers {
                avatar {
                  url
                }
                slug
                position
                name
            }
         }
         allStrapiBlackowlEvents(
            filter: {blackowl_meetup: {slug: {eq: $meetup}}, slug: {ne: $slug}}
          ) {
            nodes {
              Hero_Image {
                url
              }
              date
              description
              slug
              title
              speaker {
                avatar {
                  url
                }
                slug
                name
              }
            }
          }
   }
 `

export default EventTemplate