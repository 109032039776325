import styled from "styled-components";
import {Link} from "gatsby";

const EventMeetupLink = styled(Link)`
  padding: 10px 8px 8px 22px;
  background-image: url("/assets/arrow-red-icon.svg");
  background-position: 0px 50%;
  background-repeat: no-repeat;
  color: #1a1725;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-transform: uppercase;
`

export default EventMeetupLink;