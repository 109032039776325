import styled from "styled-components";
import {Link} from "gatsby";

const AvatarDiv = styled(Link)`
  display: grid;
  grid-template-columns: 64px auto;
  
  width: 100%;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    text-align: center;
  }

  &:hover {
    opacity: 0.8;
  }
`

export default AvatarDiv;